body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  color: white;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
}

p {
  font-size: 16px;
}

h1,
h2,
p {
  word-wrap: break-word;
}

.header-text {
  font-size: 35px;
}
h2.subtitle-text {
  font-size: 16px;
  color: #e4c950;
  font-weight: 500;
}

img {
  object-fit: cover;
}

.background-img-styled {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gold-color {
  color: #e4c950;
}
.mini-title {
  font-size: 12px;
  color: #e4c950;
  font-weight: bold;
}

.bg-original-color {
  background-color: #100e09;
}

.title {
  font-size: 35px;
}
h1 {
  font-weight: 400;
}
h2 {
  font-weight: 350;
}
.ant-menu-submenu.ant-menu-submenu-popup{
  background-color: black;
}

/* Laptops (1366px and up) */
@media only screen and (max-width: 1366px) {
  
}
/* styles for tablet screens in landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
 
}

/* styles for tablet screens in portrait orientation */
@media only screen and (max-width: 768px) {

}

/* styles for smartphone screens in landscape orientation */
@media only screen and (min-width: 480px) and (max-width: 720px) {
}

/* styles for smartphone screens in portrait orientation */
@media only screen and (max-width: 480px) {
}


/* Ant design breakpoints */

/* xs: '480px',
sm: '576px',
md: '768px',
lg: '992px',
xl: '1200px',
xxl: '1600px', */